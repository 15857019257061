import React from "react"
import { styled } from "twin.macro"
import { MapContainer, TileLayer, Circle } from "react-leaflet"

const MapWrapper = styled.div`
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
`

const center = { lat: 43.0744328, lng: -89.3844713 }
const circleCenter = { lat: 43.0471273, lng: -89.4182962 }

export const GoogleMapSection = () => {
  if (!MapContainer) {
    return null
  }
  return (
    <MapWrapper className="my-8 max-w-screen-lg mx-auto px-4 md:px-0">
      <MapContainer
        center={center}
        zoom={10}
        scrollWheelZoom={false}
        attributionControl={false}
        className="px-4 mx-auto w-full relative overflow-hidden"
        style={{ height: "450px" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Circle center={circleCenter} fillColor="#145c97" radius={25000} />
      </MapContainer>
    </MapWrapper>
  )
}
