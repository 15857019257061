import React from "react"
import loadable from "@loadable/component"
import tw, { styled } from "twin.macro"
import { H1 } from "../components/page-elements"
import { FeatureList } from "../components/feature-list"
import { Column } from "../components/styles/column"
import { GoogleMapSection } from "../components/google-map-section"
import Content from "../content/contact-page-content.json"
import SEO from "../components/seo"
import { Banner } from "../components/shared/banner"
const ScheduleSection = loadable(() => import("../components/schedule-section"))

const Section = loadable(() => import("../components/shared/section"))
const EmailContactForm = loadable(
  () => import("../components/shared/email-contact-form")
)

const backgroundImage = "glacier-background.jpg"

const Row = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  md:mx-auto
  px-page-gutter
  md:px-0
  w-full
  max-w-screen-xl
`

const InnerH1 = styled(H1)`
  ${tw`text-center text-base-color my-8 md:my-16 xl:my-20 mx-auto`}
`

const MapContainer = styled.div`
  ${tw`w-full relative overflow-hidden`}
  height:450px;
`

const MapElement = styled.div`
  ${tw`w-full h-full relative`}
`
const LoadingElement = styled.div`
  ${tw`w-full h-full relative`}
`

// const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API}&exp&libraries=geometry,drawing,places`
const url = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`

const ContactPage = () => {
  return (
    <>
      <SEO title={Content.meta.title} description={Content.meta.description} />
      <Banner src={backgroundImage}>
        <Row>
          <InnerH1 color="light">{Content.header}</InnerH1>
        </Row>
      </Banner>
      <GoogleMapSection
        googleMapURL={url}
        loadingElement={<LoadingElement />}
        containerElement={<MapContainer />}
        mapElement={<MapElement />}
      />
      <ScheduleSection />
    </>
  )
}

export default ContactPage
